import * as React from "react";
import Drawer from "@mui/material/Drawer";
import CancelIcon from "@mui/icons-material/Cancel";

interface TradesetupMessageprops {
  valuepopup?: any;
  closeDrawer: () => void;
}

const BacktestView: React.FunctionComponent<TradesetupMessageprops> = ({ valuepopup, closeDrawer }) => {
  const [state, setState] = React.useState({ right: true });

  const toggleDrawer = (anchor: "right", open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
    if (!open) closeDrawer();
  };

  const {
    algo,
    strategy,
    stock,
    created_date,
    slippage,
    status,
    quantity,
    commission,
    commission_type,
    stop_loss,
    target,
    trailing_Stop_loss,
    start_date,
    end_date,
  } = valuepopup || {};

  return (
    <Drawer anchor="right" open={state.right} onClose={toggleDrawer("right", false)} PaperProps={{
      style: {
        width: "45%",
      },
    }}>
      <div
        style={{
          
          padding: "20px",
          backgroundColor: " #f4f4f4",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #2C8E14",
            color: "white",
            padding: "16px",
            borderRadius: "8px 8px 0 0",
          }}
        >
          <span style={{ fontSize: "20px" }}>BackTest</span>
          <CancelIcon
            style={{ cursor: "pointer" }}
            onClick={toggleDrawer("right", false)}
          />
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "10px",
            marginTop: "20px",
            
            
          }}
        >
          {[
            { label: "Created Date", value: created_date || "-" },
            { label: "Algo", value: algo?.name || "-" },
            { label: "Strategy", value: strategy?.name || "-" },
            { label: "Stock Name", value: stock?.stock_name || "-" },
            { label: "Commission", value: commission || "-" },
            { label: "Commission Type", value: commission_type || "-" },
            { label: "Slippage", value: slippage || "-" },
            // { label: "Group", value: valuepopup?.groups || "-" },
            { label: "Status", value: status || "-" },
            
            { label: "Quantity", value: quantity || "-" },
            { label: "Stop Loss", value: stop_loss || "-" },
            { label: "Target", value: target || "-" },
            { label: "Trailing Stop Loss", value: trailing_Stop_loss || "-" },
            { label: "Start Date", value: start_date || "-" },
            { label: "End date", value: end_date || "-" },
          ].map(({ label, value }) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                border: "1px solid #ccc",
                borderRadius: "8px",
                padding: "10px",
                backgroundColor: "white",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                height:"60px",
                
              }}
              key={label}
            >
              <strong style={{ fontSize: "14px", }}>{label}:</strong>
              <span style={{ marginTop: "2px", fontSize: "13px", color: "#333" }}>
                {value}
              </span>
            </div>
          ))}
        </div>
      </div>
    </Drawer>
  );
};

export default BacktestView;

import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  failure_api_call_TradeList,
  success_api_call_TradeList,
} from "./list_TradeList_slicer";
import {
  failure_api_call_TradeListGroup,
  success_api_call_TradeListGroup,
} from "./list_TradeList_grouplist_slicer";

//list trade
const listTradeListCall = async (a: any) => {
  console.log(a);

  // Function to format a date as "YYYY-MM-DD"
  function formatDate(date: any) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const currentDate = new Date(); // Get the current date
  const sixDaysAgo = new Date(currentDate);
  sixDaysAgo.setDate(currentDate.getDate() - 6);
  const rolebasedata = localStorage.getItem("role");
  const listfilter = {
    entry_type: a.payload.entry_type,
    trade_type: a.payload.type, // Set default value to "strategy" if trade_type is null or undefined
    user_id: localStorage.getItem("idforlogin"),
    status: a.payload.status,
    product_type: a.payload.Product,
    stock_id: a.payload.Stocks,
    algo_id: a.payload.algo_id,
    strategy_id: a.payload.strategy_id,
    execution_type: a.payload.typelist,
    from_date:
      a.payload.from_date != null
        ? a.payload.from_date
        : formatDate(currentDate),
    to_date:
      a.payload.to_date != null ? a.payload.to_date : formatDate(currentDate),
  };
  const listType = {
    entry_type: a.payload.entry_type,
    trade_type: a.payload.type, // Set default value to "strategy" if trade_type is null or undefined
    user_id: localStorage.getItem("idforfilter"),
    status: a.payload.status,
    algo_id: a.payload.algo_id,
    strategy_id: a.payload.strategy_id,
    stock_id:a.payload.Stocks,
    from_date:
      a.payload.from_date != null
        ? a.payload.from_date
        : formatDate(currentDate),
    to_date:
      a.payload.to_date != null ? a.payload.to_date : formatDate(currentDate),
  };
  const data_for_open_position =
    rolebasedata === "USER" ? listfilter : listType;
  const get_token = localStorage.getItem("token");
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/strategy/history`,
    data_for_open_position,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* TradeListApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listTradeListCall, e);
    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,
      field_id: i + 1,
    }));
    yield put(success_api_call_TradeList(res));
  } catch (e) {
    yield put(failure_api_call_TradeList(e));
    yield put(expireSignature(e));
  }
}

const listTradeListGroupCall = async (a: any) => {
  const list = {
    strategy_id: a.payload.strategy_id,
    group_id: a.payload.group_id,
  };
  const get_token = localStorage.getItem("token");
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/strategy/group`,
    list,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* TradeListGroupApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listTradeListGroupCall, e);
    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,
      field_id: i + 1,
    }));
    yield put(success_api_call_TradeListGroup(res));
  } catch (e) {
    yield put(failure_api_call_TradeListGroup(e));
    yield put(expireSignature(e));
  }
}

import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  failure_api_call_add_AddTreadSetup,
  success_api_call_add_AddTreadSetup,
} from "./add_Trade-setup_slicer";
import {
  failure_api_call_EditTreadSetup,
  success_api_call_EditTreadSetup,
} from "./edit_Trade-setup_slicer";
import {
  failure_api_call_TreadSetupList,
  success_api_call_TreadSetupList,
} from "./list_Trade-setup_slicer";
import {
  failure_api_call_delete_TreadSetup,
  success_api_call_delete_TreadSetup,
} from "./delete_Trade-setup_slicer";
import {
  failure_api_call_AddorEditList,
  success_api_call_AddorEditList,
} from "./listaddoredit_Trade-setup_slicer";

//add tradesetup
const AddTreadSetupCall = async (a: any) => {
  const stockdata = a.payload?.Stock;
  const accountdata = a.payload?.Accounts;
  const accountgroup = a.payload?.groupaccount;
  const stokesarraydata = [stockdata].map((stock: any) => ({
    id: stock?.value,
    name: stock?.label,
    symbol: stock?.symbol,
  }));
  const GroupAccount = accountgroup?.map((groups: any) => ({
    id: groups?.value,
    name: groups?.label,
  }));
  const accountarraydata = accountdata.map((account: any) => ({
    id: account.value,
    broker: account.label?.split(",")[0],
    client_id: account.label?.split(",")[1],
  }));
  console.log(a.payload);

  const add = {
    algo_id: a.payload.algo_id,
    strategy_id: a.payload.Strategy,
    lot_size: a.payload.lot_size,
    stop_loss: a.payload.stoploss,
    quantity: a.payload.quantity,
    target: a.payload.takeprofit,
    trailing_stop_loss: a.payload.TrailingStopLoss,
    expiry_date: a.payload.Expirydate,
    original_expiry_date: a.payload.original_expiry_date,
    stocks: stokesarraydata,
    accounts: accountarraydata.length === 0 ? null : accountarraydata,
    groups: GroupAccount.length === 0 ? null : GroupAccount,
  };
  const get_token = localStorage.getItem("token");
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/strategy/trade-setup/create`,
    add,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* AddTreadSetupApi(e: any) {
  try {
    const response: AxiosResponse = yield call(AddTreadSetupCall, e);
    yield put(success_api_call_add_AddTreadSetup(response.data));
  } catch (e) {
    yield put(failure_api_call_add_AddTreadSetup(e));
    yield put(expireSignature(e));
  }
}

//Edit tradesetup
const editTreadSetupCall = async (a: any) => {
  const get_id = localStorage.getItem("tradesetupfilter");
  let edit = {};
  if (a.payload.status !== undefined) {
    edit = { status: a.payload.status }; // 1 for active, 0 for inactive
  } else {
    const stockdata = a.payload?.Stock;
    const accountdata = a.payload?.Accounts;
    const accountgroup = a.payload?.groupaccount;
    const GroupAccount = accountgroup?.map((groups: any) => ({
      id: groups?.value,
      name: groups?.label,
    }));
    const Stokedata = [stockdata].map((stock: any) => ({
      id: stock?.value,
      name: stock?.label,
      symbol: stock?.symbol,
    }));
    const accountarraydata = accountdata?.map((account: any) => ({
      id: account.value,
      broker: account.label?.split(",")[0],
      client_id: account.label?.split(",")[1],
    }));
    edit = {
      algo_id: a.payload.algo_id,
      lot_size: a.payload.lot_size,
      strategy_id: a.payload.Strategy,
      stop_loss: a.payload.stoploss,
      target: a.payload.takeprofit,
      quantity: a.payload.quantity,
      trailing_stop_loss: a.payload.TrailingStopLoss,
      expiry_date: a.payload.Expirydate,
      original_expiry_date: a.payload.original_expiry_date,
      stocks: Stokedata,
      accounts: accountarraydata.length === 0 ? null : accountarraydata,
      groups: GroupAccount.length === 0 ? null : GroupAccount,
    };
  }

  const get_token = localStorage.getItem("token");

  return Axios.put(
    `${process.env.REACT_APP_api_url}/v1/api/strategy/trade-setup/${get_id}`,
    edit,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* editTreadSetupApi(e: any) {
  try {
    const response: AxiosResponse = yield call(editTreadSetupCall, e);
    yield put(success_api_call_EditTreadSetup(response.data));
  } catch (e) {
    yield put(failure_api_call_EditTreadSetup(e));
    yield put(expireSignature(e));
  }
}

//list tradesetup
const listTreadSetupCall = async (a: any) => {
  const getuserid = localStorage.getItem("idforfilter") !== null;
  let list: any = getuserid
    ? { user_id: localStorage.getItem("idforfilter") }
    : {};
  if (a.payload !== "undefined") {
    list = {
      ...list,
      algo_id: a.payload.algo_id,
      strategy_id: a.payload.strategy_id,
      status: a.payload.status,
    };
  }

  const get_token = localStorage.getItem("token");
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/strategy/trade-setup`,
    list,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* listTreadSetupApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listTreadSetupCall, e);
    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,
      field_id: i + 1,
    }));
    yield put(success_api_call_TreadSetupList(res));
  } catch (e) {
    yield put(failure_api_call_TreadSetupList(e));
    yield put(expireSignature(e));
  }
}

// add or edit tradesetup
const listAddorEditCall = async (a: any) => {
  const getuserid = localStorage.getItem("idforfilter");
  let list = {};
  if (getuserid !== null) {
    list = { user_id: localStorage.getItem("idforfilter") };
  } else {
    list = {};
  }
  const get_token = localStorage.getItem("token");
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/strategy/map`,
    list,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};

export function* listAddorEditApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listAddorEditCall, e);

    // Filter the response data to include only items with status === 0
    let res = response?.data?.data?.filter((x: any) => x.status === 0)
      .map((x: any, i: any) => ({
        ...x,
        field_id: i + 1,
      }));

    yield put(success_api_call_AddorEditList(res));
  } catch (e) {
    yield put(failure_api_call_AddorEditList(e));
    yield put(expireSignature(e));
  }
}

// Delete
export function* deleteTreadSetupApi(e: any) {
  try {
    const response: AxiosResponse = yield call(deleteTreadSetupCall, e);
    yield put(success_api_call_delete_TreadSetup(response.data));
  } catch (error) {
    yield put(failure_api_call_delete_TreadSetup(error));
  }
}
const deleteTreadSetupCall = (a: any) => {
  const token = localStorage.getItem("token");
  const formData = new FormData();
  formData.append("id", a.payload);
  return Axios.delete(
    `${process.env.REACT_APP_api_url}/v1/api/strategy/trade-setup/${a.payload.id}`,
    {
      data: formData,
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

import { FunctionComponent, useState } from "react";
import "./backtest.css";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import RefreshIcon from "@mui/icons-material/Refresh";
import * as React from "react";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Alert,
  Button,
  Snackbar,
  Typography,
  LinearProgress,
} from "@mui/material";
import Grids from "../../Component/Grides";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import DialogBacktest from "../../Component/DialogForFormBacktest";
import Backtestview from "./backtestMessage";
import Dialogs from "../../Component/Dialog";
import {
  clearDatabacktest,
  list_backtest,
} from "../../Redux/Reducers/back-test/list_backtest_slicer";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { add_addbacktest } from "../../Redux/Reducers/back-test/add_backtest_slicer";
import { false_switchDeletebacktestvalues } from "../../Redux/Reducers/Datagrid-values/DeleteBacktest-values-slicer";
import {
  delete_backtest,
  false_switchdeletebacktest_response,
} from "../../Redux/Reducers/back-test/delete_Backtest_slicer";
import Backtestfilter from "../../Component/backtestfilter";
import { list_AddorEditList } from "../../Redux/Reducers/Trade-setup/listaddoredit_Trade-setup_slicer";
import { list_MarketholidayList } from "../../Redux/Reducers/Marketholiday/list_Marketholiday_slicer";
import { list_StrategiesList } from "../../Redux/Reducers/Strategies_api/list_StrategiesList_slicer";
import BacktestView from "../../Component/backtestView";

interface backtestProps {}
const BackTest: FunctionComponent<backtestProps> = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  
  const handleDoubleClick = (params: GridRenderCellParams<any>) => {
      setSelectedRowData(params.row);
      setDrawerOpen(true);
    };
  const dispatcher = useAppDispatch();
  const breadcrumbs = [
    <Typography
      sx={{
        fontSize: "17px",
        fontFamily: "Roboto",
      }}
    >
      Algo Trading
    </Typography>,
    <Typography
      sx={{
        fontSize: "16px",
        color: "#BDBDBD",
        fontFamily: "Roboto",
      }}
    >
      Back Test
    </Typography>,
  ];

  //    datas of backtest
  const datas = useAppSelector((state) => state?.backtest?.data);
  const isLoadingdata = useAppSelector((state) => state?.backtest?.isLoading);
  const addData: any = useAppSelector((state) => state?.addbacktest?.data);
  const addDataIsLoading = useAppSelector(
    (state) => state?.addbacktest?.isLoading
  );
  const Uservaluesbacktestdelete: any = useAppSelector(
    (state) => state.Deletebacktestvalues.data
  );
  const switchOpenalgodelete = useAppSelector(
    (state) => state.Deletebacktestvalues.switch
  );
  const switchResponsealgodelete = useAppSelector(
    (state) => state.Deletebacktest.switchResponse
  );
  const deleteData: any = useAppSelector(
    (state) => state?.Deletebacktest?.data
  );

  const column = [
    {
      headerName: "Created Date",
      flex: 1,
      field: "created_date",
      renderCell: (params: GridRenderCellParams<any>) => {
        if (
          params?.row?.created_date === null ||
          params?.row?.created_date === ""
        ) {
          return "-";
        }
        const createdName = params?.row?.created_date;
        return (
          <div
          onDoubleClick={() => handleDoubleClick(params)}
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              cursor: "pointer", 
                height:"100%", 
                width:"100%", 
                textAlign:"left", 
                marginTop:"20%"
            }}
            title={createdName}
          >
            {createdName}
          </div>
        );
      },
    },
    {
      headerName: "Algo",
      flex: 1,
      field: "algo",
      valueGetter: (params: any) => {
        return params?.row?.algo?.name;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params?.row?.algo?.name === null) {
          return "-";
        }
        const algoName = params?.row?.algo?.name;
        return (
          <div
          onDoubleClick={() => handleDoubleClick(params)}
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              cursor: "pointer", 
                height:"100%", 
                width:"100%", 
                textAlign:"left", 
                marginTop:"20%"
            }}
            title={algoName}
          >
            {algoName}
          </div>
        );
      },
    },

    {
      headerName: "Strategy",

      field: "strategy",
      flex: 1,
      valueGetter: (params: any) => {
        return params?.row?.strategy?.name;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object
        if (params?.row?.strategy?.name === null) {
          return "-";
        }
        const strategyName = params?.row?.strategy?.name;

        return (
          <div
          onDoubleClick={() => handleDoubleClick(params)}
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              cursor: "pointer", 
                height:"100%", 
                width:"100%", 
                textAlign:"left", 
                marginTop:"20%"
            }}
            title={strategyName}
          >
            {strategyName}
          </div>
        );
      },
    },
    {
      headerName: "Stock",
      field: "stock",
      flex: 1,
      valueGetter: (params: any) => {
        return params?.row?.stock?.stock_name;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params?.row?.stock?.stock_name === null) {
          return "-";
        }
        const stockName = params?.row?.stock?.stock_name;

        return (
          <div
          onDoubleClick={() => handleDoubleClick(params)}
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              cursor: "pointer", 
                height:"100%", 
                width:"100%", 
                textAlign:"left", 
                marginTop:"20%"
            }}
            title={stockName}
          >
            {stockName}
          </div>
        );
      },
    },

    {
      headerName: "Start Date",

      field: "start_date",
      flex: 0.7,
      valueGetter: (params: any) => {
        const date = new Date(params.value);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        const formattedDate = params.value ? params.value : "-";
        return (
          <div
            onDoubleClick={() => handleDoubleClick(params)}
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              cursor: "pointer",
              height: "100%",
              width: "100%",
              textAlign: "left",
              marginTop: "30%",
            }}
            title={formattedDate}
          >
            {formattedDate}
          </div>
        );
      },
    },
    {
      headerName: "End Date",
      field: "end_date",
      flex: 0.7,
      valueGetter: (params: any) => {
        const date = new Date(params.value);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        const formattedDate = params.value ? params.value : "-";
        return (
          <div
            onDoubleClick={() => handleDoubleClick(params)}
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              cursor: "pointer",
              height: "100%",
              width: "100%",
              textAlign: "left",
              marginTop: "30%",
            }}
            title={formattedDate}
          >
            {formattedDate}
          </div>
        );
      },
    },
    {
      headerName: "Status",

      field: "status",
      flex: 0.6,
      renderCell: (params: GridRenderCellParams<any>) => {
        const statusValue = params.value ? params.value : "-";
        return (
          <div
            onDoubleClick={() => handleDoubleClick(params)}
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              cursor: "pointer",
              height: "100%",
              width: "100%",
              textAlign: "left",
              marginTop: "30%",
            }}
            title={statusValue}
          >
            {statusValue}
          </div>
        );
      },
    },

    true
      ? {
          headerName: "Action",

          field: "Action",
          flex: 1,
          type: "actions",
          renderCell: (params: any) => {
            return (
              <div
              onDoubleClick={() => handleDoubleClick(params)}
              style={{ cursor: "pointer", height:"100%", width:"100%", padding:"10px 50px 10px 50px" }}>

              <Backtestview
                valuepopup={params.row.id}
                editdata={params.row}
              ></Backtestview>
              </div>
            );
          },
        }
      : {
          flex: 1,
          type: "actions",
          hide: true,
          display: false,
          renderCell: (params: any) => {
            return (<div
              onDoubleClick={() => handleDoubleClick(params)}
              style={{ cursor: "pointer", height:"100%", marginTop:"20%" }}>
              </div>);
          },
        },
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    created_date: true,
    algo: true,
    strategy: true,
    stock: true,
    start_date: true,
    end_date: true,
    status: true,
    Action: true,
  });

  React.useEffect(() => {
    dispatcher(list_backtest(""));
    dispatcher(list_AddorEditList(""));
    dispatcher(list_MarketholidayList(""));
    dispatcher(list_StrategiesList(""));
    if (switchResponsealgodelete) {
      dispatcher(false_switchDeletebacktestvalues());
    }
    localStorage.removeItem("backreportsummary");
    return () => {
      dispatcher(clearDatabacktest());
    };
  }, [dispatcher, addData, deleteData, switchResponsealgodelete]);

  const actions = (
    <button
      onClick={() => {
        dispatcher(false_switchdeletebacktest_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={switchResponsealgodelete} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchdeletebacktest_response())}
        key={`top-right`}
        action={actions}
      >
        <Alert
          onClose={() => dispatcher(false_switchdeletebacktest_response())}
          severity="success"
        >
          {deleteData.message}
        </Alert>
      </Snackbar>
      <div>
        <LinearProgress
          style={{
            color: "black",
            width: "100%",
            marginTop: "-15px",
            display: isLoadingdata || addDataIsLoading ? "block" : "none",
          }}
        />
      </div>
      <Stack
        spacing={10}
        className="breadcrumbs"
        sx={{ margin: "0px 0px 1.5% 1%" }}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      {drawerOpen && selectedRowData && (
        <BacktestView valuepopup={selectedRowData} closeDrawer={() => setDrawerOpen(false)} />
      )}

      <Grids
        row={datas ?? []}
        column={column}
        height={"90%"}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        opens
        sideButton={<Backtestfilter></Backtestfilter>}
        pagination
        refericon={
          <span
            title="Refresh"
            onClick={() => {
              dispatcher(list_backtest(""));
            }}
          >
            <RefreshIcon
              sx={{ fontSize: "24px", marginTop: "6px", cursor: "pointer" }}
            ></RefreshIcon>
          </span>
        }
        dialog={
          <DialogBacktest
            successMessage={addData}
            isLoading={addDataIsLoading}
            sx={{
              padding: "15px",
              marginLeft: "10px",
              background: "linear-gradient(to right, #FFFA02,#F2A618 )",
              color: "black",
              width: {
                xs: 60,
                sm: 100,
                md: 100,
                lg: 100,
                xl: 150,
              },
              height: "37px",
              borderRadius: "5px",
              fontSize: {
                xs: "10px",
                sm: "10px",
                md: "10px",
                lg: "10px",
                xl: "12px",
              },
              fontFamily: "Roboto",
              textTransform: "capitalize",
              "&:hover": { backgroundColor: "#EF990F" },
            }}
            getValues={(v: any) => {
              dispatcher(add_addbacktest(v));
            }}
            Buttondialog={["Cancel"]}
            Dialogtitle={"Generate Report"}
            Buttonname={"Generate Report"}
          />
        }
        Userpermissions={undefined}
      ></Grids>
      <Dialogs
        maxWidth={"xs"}
        subtitle={"Warning"}
        open={switchOpenalgodelete}
        setOpen={() => dispatcher(false_switchDeletebacktestvalues())}
        Dialog_Content={"Do you want to delete ?"}
        Dialog_Actions={
          <>
            <Button
              sx={{
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                border: "1px solid #F2A618",
                padding: "5px 0",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "white" },
              }}
              onClick={() => dispatcher(false_switchDeletebacktestvalues())}
            >
              No
            </Button>
            <Button
              autoFocus
              sx={{
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                "&:hover": {
                  background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                },
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                marginLeft: "5%",
              }}
              onClick={() =>
                dispatcher(delete_backtest(Uservaluesbacktestdelete))
              }
            >
              yes
            </Button>
          </>
        }
      />
    </div>
  );
};

export default BackTest;

import { FunctionComponent, useEffect, useState } from "react";
import {
  DataGrid,
  GridPagination,
  GridToolbar,
  useGridApiContext,
  gridPageCountSelector,
  useGridSelector,
  GridRowHeightParams,
  GridRowHeightReturnValue,
} from "@mui/x-data-grid";
import "../App.css";
import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, TablePaginationProps } from "@mui/material";
import MuiPagination from "@mui/material/Pagination";
import "../pages/Page.css";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import { edit_Userpermission } from "../Redux/Reducers/User_permission/edit_User_permission_slicer";
import { list_User_permission } from "../Redux/Reducers/User_permission/list_User_permission_slicer";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Selects from "@mui/material/Select";
type FooterStatus = "connected" | "disconnected";

declare module "@mui/x-data-grid" {
  interface FooterPropsOverrides {
    status: FooterStatus;
    gatpass?: any;
    pagination?: any;
    TotalPosition?: any;
    Userpermissions?: any;
  }
}

interface GridsProps {
  checkboxActive?: any;
  Userpermissions?: any;
  UserPermissionFordropdown?: any;
  pagination?: any;
  TotalPosition?: any;
  gatpass?: boolean;
  scrollbarSize?: number;
  column?: any;
  Users?: any;
  dialog?: any;
  dialogopenposition?: any;
  sideButton?: any;
  holding?: any;
  row?: any;
  columnVisibilityModel?: any;
  setColumnVisibilityModel?: any;
  serach?: any;
  swithoutbutton?: boolean;
  showedoutbuttonchack?: boolean;
  opened?: boolean;
  Removetheborder?: any;
  open?: boolean;
  opens?: boolean;
  Export?: any;
  refericon?: any;
  paperTrade?: any;
  Camera?: boolean;
  UserPermissionenbale?: boolean;
  UserPermission?: boolean;
  ServiceStatus?: boolean;
  gridesbutton?: boolean;
  data?: any;
  roleData?: any;
  Submitbutton?: any;
  Gatepassbutton?: any;
  Servicestatusbutton?: any;
  componet?: any;
  height?: any;
  setDate?: any;
  getRowId?: string;
  getRowHeight?: (params: GridRowHeightParams) => GridRowHeightReturnValue;
}

//  Style the component

const useStyles = makeStyles({
  dataGrid: {
    "& .MuiDataGrid-virtualScroller": {
      "&::-webkit-scrollbar": {
        width: "0px",
        height: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "grey",
        borderRadius: "5px",
      },
    },
  },
});

const NoRowsOverlay = (props: any) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 10000); // 2 seconds delay

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: "15px",
      }}
    >
      {loading || props.row !== undefined ? "Loading..." : "Not Found"}
    </Box>
  );
};

//    Custome Toolbar
const CustomToolbar = (props: any) => {
  const RoleData = useAppSelector((state) => state?.RoleList?.data);
  const dispatcher = useAppDispatch();
  const roleid = RoleData?.map((v: any) => ({
    value: v.id,
  }));

  const [portalandroleid, setportalandroleid] = React.useState(
    roleid[0]?.value
  );
  localStorage.setItem("roleIdforuserpermission", portalandroleid);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginLeft: "1px",
          marginBottom: "10px",
          alignItems: "center",
        }}
      >
        <span
          style={{
            fontSize: "19px",
            lineHeight: "23px",
            width: "100%",
            color: "#000000",
          }}
        >
          {props.Users}
        </span>

        {props.show ? (
          <div style={{ display: "flex", margin: " 0 20px " }}>
            <div style={{ color: "rgb(241 183 7)" }}>{props.refericon}</div>
            <div style={{ color: "rgb(241 183 7)" }}>{props.sideButton}</div>
            <GridToolbar
              {...props}
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                padding: "4px 10px 0px 0px",
                gap: "0px",
                marginTop: "-3px",
                "& .MuiInputBase-root.MuiInput-root": {
                  backgroundColor: "white",
                  border: "1px solid rgb(241 183 7)",
                  borderRadius: "5px",
                  height: "2.2rem",
                  width: {
                    sm: 100,
                    md: 200,
                    lg: 210,
                    xl: 275,
                  },
                },
                "& .MuiButtonBase-root": {
                  minWidth: 0,
                  marginTop: "-5px",
                  marginLeft: "5px",
                },
              }}
            ></GridToolbar>

            <div>{props?.dialog}</div>
          </div>
        ) : undefined}
        {props.showed ? (
          <div style={{ display: "flex" }}>
            <div style={{ color: "rgb(241 183 7)" }}>{props.refericon}</div>
            <div style={{ color: "rgb(241 183 7)" }}>{props.sideButton}</div>
            <div style={{ color: "rgb(241 183 7)" }}>{props.Export}</div>
            <GridToolbar
              {...props}
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                padding: "4px 0px 0px 0px",
                gap: "0px",
                marginTop: "-3px",
                "& .MuiInputBase-root.MuiInput-root": {
                  backgroundColor: "white",
                  border: "1px solid rgb(241 183 7)",
                  borderRadius: "5px",
                  height: "2.2rem",
                  width: {
                    sm: 100,
                    md: 200,
                    lg: 210,
                    xl: 275,
                  },
                },
                "& .MuiButtonBase-root": {
                  minWidth: 0,
                  marginTop: "-5px",
                  marginLeft: "5px",
                },
              }}
            ></GridToolbar>

            <div>{props?.dialog}</div>
          </div>
        ) : undefined}
        {props.showedoutbutton ? (
          <div style={{ display: "flex", margin: " 0 20px " }}>
            <div style={{ color: "rgb(241 183 7)" }}>{props.sideButton}</div>
            <GridToolbar
              {...props}
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                padding: "4px 10px 0px 0px",
                gap: "0px",
                marginTop: "-3px",
                "& .MuiInputBase-root.MuiInput-root": {
                  backgroundColor: "white",
                  border: "1px solid rgb(241 183 7)",
                  borderRadius: "5px",
                  height: "2.2rem",
                  width: {
                    sm: 100,
                    md: 200,
                    lg: 210,
                    xl: 275,
                  },
                },
                "& .MuiButtonBase-root": {
                  minWidth: 0,
                  marginTop: "-5px",
                  marginLeft: "5px",
                },
              }}
            ></GridToolbar>

            <div style={{ marginRight: "-34px" }}>{props?.dialog}</div>
          </div>
        ) : undefined}
        {props.gridesbutton ? (
          <div style={{ display: "flex", margin: " 0 20px " }}>
            <div style={{ color: "rgb(241 183 7)" }}>{props.sideButton}</div>
            <GridToolbar
              {...props}
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                padding: "4px 10px 0px 0px",
                gap: "0px",
                marginTop: "-3px",
                "& .MuiInputBase-root.MuiInput-root": {
                  backgroundColor: "white",
                  border: "1px solid rgb(241 183 7)",
                  borderRadius: "5px",
                  height: "2.2rem",
                  width: {
                    sm: 100,
                    md: 200,
                    lg: 210,
                    xl: 275,
                  },
                },
                "& .MuiButtonBase-root": {
                  minWidth: 0,
                  marginTop: "-5px",
                  marginLeft: "5px",
                },
              }}
            ></GridToolbar>

            <div style={{ marginRight: "-20px" }}>{props?.dialog}</div>
          </div>
        ) : undefined}
        {props.shows ? (
          <div style={{ display: "flex" }}>
            <div style={{ color: "rgb(241 183 7)" }}>{props.refericon}</div>
            <div style={{ color: "rgb(241 183 7)" }}>{props.sideButton}</div>
            <div style={{ color: "rgb(241 183 7)" }}>{props.Export}</div>
            <GridToolbar
              {...props}
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                padding: "4px 0px 0px 0px",
                gap: "0px",
                marginTop: "-3px",
                "& .MuiInputBase-root.MuiInput-root": {
                  backgroundColor: "white",
                  border: "1px solid rgb(241 183 7)",
                  borderRadius: "5px",
                  height: "2.2rem",
                  width: {
                    sm: 100,
                    md: 200,
                    lg: 210,
                    xl: 275,
                  },
                },
                "& .MuiButtonBase-root": {
                  minWidth: 0,
                  marginTop: "-5px",
                  marginLeft: "5px",
                },
              }}
            ></GridToolbar>

            <div>{props?.dialog}</div>
            <div>{props?.dialogopenposition}</div>
          </div>
        ) : undefined}

        {props.showswithoutbutton ? (
          <div style={{ display: "flex" }}>
            <div style={{ color: "rgb(241 183 7)" }}>{props.refericon}</div>
            <div style={{ color: "rgb(241 183 7)" }}>{props.sideButton}</div>
            <div style={{ color: "rgb(241 183 7)" }}>{props.Export}</div>

            <GridToolbar
              {...props}
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                padding: "4px 0px 0px 0px",
                gap: "0px",
                marginTop: "-3px",
                "& .MuiInputBase-root.MuiInput-root": {
                  backgroundColor: "white",
                  border: "1px solid rgb(241 183 7)",
                  borderRadius: "5px",
                  height: "2.2rem",
                  width: {
                    sm: 100,
                    md: 200,
                    lg: 210,
                    xl: 275,
                  },
                },
                "& .MuiButtonBase-root": {
                  minWidth: 0,
                  marginTop: "-5px",
                  marginLeft: "5px",
                },
              }}
            ></GridToolbar>

            <div>{props?.dialog}</div>
          </div>
        ) : undefined}
        {props.Permission ? (
          <>
            {/* <GridToolbar
              {...props}
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                padding: "4px 10px 0px 0px",
                gap: "0px",
                marginTop: "-3px",
                "& .MuiInputBase-root.MuiInput-root": {
                  backgroundColor: "white",
                  border: "1px solid rgb(241 183 7)",
                  borderRadius: "5px",
                  height: "2.2rem",
                  width: {
                    sm: 100,
                    md: 200,
                    lg: 210,
                    xl: 275,
                  },
                },
                "& .MuiButtonBase-root": {
                  minWidth: 0,
                  marginTop: "-5px",
                  marginLeft: "5px",
                },
              }}
            ></GridToolbar> */}
            <FormControl sx={{ minWidth: 220 }} size="small">
              <InputLabel id="demo-select-small-label">
                {" "}
                Select the role
              </InputLabel>
              <Selects
                labelId="demo-select-small-label"
                id="demo-select-small"
                // value={age}
                label="Select the role"
                onChange={(e: any, val: any) => {
                  props.setRoleId(val?.value);
                  dispatcher(
                    list_User_permission([
                      {
                        status: true,
                        portal: props?.UserPermissionFordropdown,
                        roleId: e?.target?.value?.id,
                      },
                    ])
                  );
                  setportalandroleid(e?.target?.value?.id);
                }}
              >
                {RoleData.map((v: any) => (
                  <MenuItem key={v.id} value={v}>
                    {v.name}
                  </MenuItem>
                ))}
              </Selects>
            </FormControl>
          </>
        ) : undefined}
      </div>
      <div>{props.holding}</div>
    </>
  );
};

function Pagination({
  page,
  onPageChange,
  className,
}: Pick<TablePaginationProps, "page" | "onPageChange" | "className">) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event: any, newPage: any) => {
        onPageChange(event, newPage - 1);
      }}
    />
  );
}

const Grids: FunctionComponent<GridsProps> = (props) => {
  const CustomColumnHeader = (params: any) => (
    <div
      className="headingnamebold"
      style={{ color: "white", fontWeight: "bolder" }}
    >
      {params?.colDef?.headerName}
    </div>
  );
  const columnsWithCustomHeader = props.column.map((col: any) => ({
    ...col,
    renderHeader: CustomColumnHeader,
  }));
  const classes = useStyles();
  const [tableData, setTableData] = useState<any[]>([]);
  const dispatche = useAppDispatch();
  const [roleId, setRoleId] = useState(0);

  //    for getting values from data gride
  function useApiRef() {
    const apiRef = React.useRef<any>(null);
    const _columns = React.useMemo(
      () =>
        props.column.concat({
          field: "__HIDDEN__",
          width: 0,
          maxWidth: 0,
          type: "actions",
          // headerName: false,
          // hideable: true,
          hide: true,
          display: false,
          // editable: true,
          renderCell: (params: any) => {
            apiRef.current = params.api;
            return null;
          },
        }),
      []
    );

    return { apiRef, columns: _columns };
  }

  const { apiRef, columns } = useApiRef();
  const handleClickButton = () => {
    const array = Array.from(apiRef.current.getRowModels());
    const result: any = array.map((v: any) => {
      return { ...v[1], roleId: roleId };
    });

    dispatche(edit_Userpermission(result));
  };

  useEffect(() => {
    setTableData(props?.row);
  }, [setTableData, props?.row]);

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });

  function customFooterStatusComponent(props: any) {
    return (
      <div className={classes.dataGrid}>
        <div>{props?.TotalPosition}</div>
        {props?.pagination && (
          <GridPagination ActionsComponent={Pagination} {...props} />
        )}
        {props?.Userpermissions && (
          <div
            className="rightAllign"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                // setDialog(dialog + 0.001);
                handleClickButton();
              }}
              sx={{
                padding: "10px",
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                color: "black",
                width: {
                  xs: 60,
                  sm: 100,
                  md: 100,
                  lg: 100,
                  xl: 150,
                },
                height: "40px",
                borderRadius: "4px",
                fontSize: "14px",
                fontWeight: "bold",
                cursor: "pointer",
                textTransform: "capitalize",
                border: "1px solid #BDBDBD",
                margin: "10px 10px 10px 10px ",
              }}
            >
              Update
            </Button>
          </div>
        )}
      </div>
    );
  }

  // footer button
  return (
    <div
      style={{
        flex: "1",
        width: "100%",
        backgroundColor: "white",
        transition: "all 1s linear",
        borderRadius: props.Removetheborder === "yes" ? "0px" : "10px",
        position: "sticky",
        zIndex: "1",
        top: "0",
        overflow: "auto", // Add this line to hide the scrollbar
        boxShadow:
          props.Removetheborder === "yes"
            ? "0px"
            : "6px 6px 20px rgba(0, 0, 0, 0.15)",
        display: "grid",
        gridTemplateColumns: "100%",
        margin: " 0 auto",
        padding: props.Removetheborder === "yes" ? "0px" : "25px",
      }}
      className={`${classes.dataGrid}`}
    >
      <DataGrid
        sx={{
          border: "none",
          borderRadius: "0",
          "&::-webkit-scrollbar": { display: "none" },
          "& .MuiDataGrid-root": {
            "& .MuiPopper-root": {
              transform: "translateX(100%) translateY(-50%) !important", // Adjust the positioning as needed
              left: "auto !important",
              right: "0 !important",
            },
          },
        }}
        classes={{
          columnHeader: "custom-header",
        }}
        localeText={{ toolbarColumns: "" }}
        disableColumnFilter
        disableDensitySelector
        getRowHeight={props.getRowHeight}
        slots={{
          toolbar: CustomToolbar,
          footer: customFooterStatusComponent,
          noRowsOverlay: NoRowsOverlay,
        }}
        getRowClassName={(params) => {
          return params.row.shouldHaveBordercheckboxActive
            ? "custom-row"
            : "custom-row no-border";
        }}
        slotProps={{
          toolbar: {
            csvOptions: { disableToolbarButton: true },
            printOptions: { disableToolbarButton: true },
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            Users: props.Users,
            dialog: props.dialog,
            showedoutbutton: props.showedoutbuttonchack,
            gridesbutton: props.gridesbutton,
            show: props.open,
            shows: props.opens,
            showed: props.opened,
            Removetheborder: props.Removetheborder,
            showswithoutbutton: props.swithoutbutton,
            CameraButton: props.Camera,
            UserPermissionFordropdown: props.UserPermissionFordropdown,
            Permission: props.UserPermission,
            data: props?.roleData,
            ServiceStatus: props.ServiceStatus,
            setDate: props.setDate,
            sideButton: props.sideButton,
            holding: props.holding,
            Export: props.Export,
            refericon: props.refericon,
            paperTrade: props.paperTrade,
            setRoleId: setRoleId,
            dialogopenposition: props.dialogopenposition,
          },
          footer: {
            gatpass: props.gatpass,
            pagination: props.pagination,
            TotalPosition: props.TotalPosition,
            Userpermissions: props.Userpermissions,
          },
        }}
        columnVisibilityModel={props?.columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          props?.setColumnVisibilityModel(newModel)
        }
        disableColumnMenu
        rows={tableData}
        columns={props.UserPermissionenbale ? columns : columnsWithCustomHeader}
        getRowId={(row) => (row?.field_id ? row?.field_id : row.id)}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        {...tableData}
        disableRowSelectionOnClick
        checkboxSelection={props.checkboxActive === "checkboxSelection"}
      />
    </div>
  );
};

export default Grids;

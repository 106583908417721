import { FunctionComponent } from "react";
import { useAppDispatch } from "../../Redux/store/hooks";
import editimage from "../Image/Edit.svg";
import { useNavigate } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { edit_ProfileValue } from "../../Redux/Reducers/Admin-api/profile_details_silcer";
import { Switch } from "@mui/material";
import { edit_EditableValue } from "../../Redux/Reducers/Admin-api/editableValu";
import { Uservalues_datagrid } from "../../Redux/Reducers/Datagrid-values/User-values";

interface UserMessageProps {
  valuepopup: number;
  editdata: any;
}

const UserMessage: FunctionComponent<UserMessageProps> = (props: any) => {
  const dispatcher = useAppDispatch();
  const navigate = useNavigate();
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const pageName = "View User";
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );
  const test_val = menuList
    .map((data: any) => {
      return data.sub_menus.filter((child: any) => {
        return child.name === pageName;
      });
    })
    .filter((final: any) => {
      return final.length > 0;
    })
    .reduce((retus: any, currentarray: any) => {
      return currentarray;
    }, {});
  const MenuOfUserpermission = test_val[0];

  return (
    <div
      style={{
        display: "flex",
        // flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {MenuOfUserpermission?.permission?.view ? (
        <RemoveRedEyeIcon
          titleAccess="View the user details"
          style={{ color: "rgb(239, 153, 15)", cursor: "pointer" }}
          onClick={() => {
            dispatcher(edit_ProfileValue(props.editdata));
            const dataToPass = props.editdata; // Replace with your actual data
            localStorage.setItem("ProfileDetailsname", dataToPass.name);
            localStorage.setItem("ProfileDetailsemail_id", dataToPass.email_id);
            localStorage.setItem(
              "ProfileDetailsmobile_no",
              dataToPass.mobile_no
            );
            localStorage.setItem("ProfileDetailsis_live", dataToPass.is_live);
            localStorage.setItem("ProfileDetailsstatus", dataToPass.status);
            localStorage.setItem("ProfileDetailsrole", dataToPass.role);
            localStorage.setItem("idforfilter", dataToPass.id);
            localStorage.setItem("viewedData", JSON.stringify(dataToPass)); // Store data in local storage
            localStorage.setItem(
              "viewedDataLive",
              JSON.stringify(dataToPass.is_live)
            );
            navigate("/Userdetails");
          }}
        ></RemoveRedEyeIcon>
      ) : null}

      {MenuOfUserpermission?.permission?.edit ? (
        <Switch
          title="Trade status Active / Inactive"
          {...label}
          size="small" // Add this line to set the switch size to small
          checked={props.editdata.is_live}
          onChange={(e) => {
            // Change the status to 0 or 1 based on switch value
            const newStatus = e.target.checked ? 1 : 0;
            dispatcher(
              Uservalues_datagrid({
                id: props.valuepopup,
                is_live: newStatus,
                status: newStatus, // Update the status to 0 or 1
              })
            );
          }}
        />
      ) : null}
      {MenuOfUserpermission?.permission?.edit ? (
        <span
          style={{ cursor: "pointer" }}
          title="Edit"
          onClick={() => {
            dispatcher(edit_EditableValue(props.editdata));
            navigate("/adduser", { state: { editoradd: "edit" } });
            const dataToPass = props.editdata; // Replace with your actual data
            localStorage.setItem("ProfileDetailsname", dataToPass.name);
            localStorage.setItem("ProfileDetailsemail_id", dataToPass.email_id);
            localStorage.setItem(
              "ProfileDetailsmobile_no",
              dataToPass.mobile_no
            );
            localStorage.setItem("ProfileDetailsis_live", dataToPass.is_live);
            localStorage.setItem("ProfileDetailsstatus", dataToPass.status);
            localStorage.setItem("ProfileDetailsrole", dataToPass.role);
            localStorage.setItem("idforfilter", dataToPass.id);
          }}
        >
          <img src={editimage} width="25px" alt={""}></img>
        </span>
      ) : null}
    </div>
  );
};

export default UserMessage;
